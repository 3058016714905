import React from 'react'
import ReactDOM from 'react-dom'
import { AppLoaded } from './services/Teams'

import App from './App'
import './css/backgound.css'
import * as serviceWorker from './serviceWorker'
import FileOffline from './pages/FileOffline'
import { MSAppGTM } from './services/utils'
import Preloader from './pages/components/Loader'
import CssBaseline from '@material-ui/core/CssBaseline'
import { KeyboardPress } from './services/utils'

import { checkLocalUrl } from './services/Addin'

const officeScriptUrl = 'https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js'

const isOffice = window.location.pathname

// Render the loader before all
ReactDOM.render(
    <CssBaseline>
        <Preloader />
    </CssBaseline>,
    document.getElementById('root')
)

const appComponent = (isTeams = false) => {
    if (isTeams) {
        MSAppGTM('Using MS Teams App')
    }
    if (checkLocalUrl()) {
        MSAppGTM('Offline Page')

        ReactDOM.render(
            <FileOffline />,
            document.getElementById('root')
        )
    } else {
        AppLoaded()
        ReactDOM.render(
            <App />,
            document.getElementById('root')
        )
    }
}

if (isOffice !== "/addin" && isOffice !== "/provider-auth") {
    appComponent(true)
} else {
    // Office JavaScript API
    window.loadSync(officeScriptUrl, function () {
        console.log('Loaded!')

        const Office = window.Office

        Office.onReady(function (info) {
            if (info && info.platform === 'PC') {
                MSAppGTM('Using Excel Desktop')
            } else {
                MSAppGTM('Using Excel Online')
            }

            window.history.replaceState = window._historyCache.replaceState;
            window.history.pushState = window._historyCache.pushState;
            console.log(`Office.js is now ready in ${info.host} on ${info.platform}`)
            appComponent()
        })
    }, function (err) {
        console.error('Something went wrong!', err)
    })
}

serviceWorker.unregister()
KeyboardPress()