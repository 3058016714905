
import Request from './request'
import { currentEnvName } from '../../constants/env'

class Api {
    constructor(args) {
        this.request = new Request(args)
    }

    getWorkflow(callback) {
        this.request.send('workflows', 'GET', true, null).then(resp => {
            if (resp.status === 1) {
                callback(resp.values)
            } else {
                callback(null)
                console.log(resp)
            }
        })
    }

    createWorkflow(payload, callback) {
        this.request.send('workflow/create', 'POST', true, payload).then(resp => {
            if (resp.status === 1) {
                callback(resp.values)
            } else {
                callback(null)
                console.log(resp)
            }
        })
    }

    signUp(payload, callback) {
        this.request.send('user/sign_up', 'POST', true, payload).then(resp => {
            if (resp.status === 1) {
                callback(resp.values)
            } else {
                callback(null)
                console.log(resp)
            }
        })
    }

    getRefreshToken() {
        this.request.send('refresh-token', 'GET', true, null).then(resp => {
            if (resp.status === 1) {
                const token = {
                    ...{
                        env: currentEnvName
                    },
                    ...resp.values
                }
                const encodedToken = new Buffer(JSON.stringify(token)).toString('base64')
                localStorage.setItem("sheetgo_current_token", encodedToken)
            } else {
                console.log(resp)
            }
        })
    }

    getFileIdByPath(payload, callback) {
        this.request.send('storage/get-file-by-path', 'POST', true, payload).then(resp => {
            if (resp.status === 1) {
                callback(resp.values)
            } else {
                callback(resp)
                console.log(resp)
            }
        })
    }

    setProvider(payload, success, failure) {
        this.request.send('auth/connect-provider', 'POST', true, payload).then(resp => {
            if ([105, 1].includes(resp.status)) {
                success(resp.values)
            } else {
                failure(resp)
                console.log(resp)
            }
        })
    }

    getToken(payload, success, failure) {
        this.request.send('auth/token', 'POST', false, payload).then(resp => {
            if ([105, 1].includes(resp.status)) {
                success(resp.values)
            } else {
                failure(resp)
            }
        })
    }
}

export default Api