import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import { 
    Grid,
    Grow,
    Typography
} from '@material-ui/core'

import { ReactComponent as LogoImage } from '../../../images/logo/symbol.svg'


const styles = theme => ({
    root: {
        position: 'absolute',
        height: '100%',
        zIndex: 99999,
        userSelect: 'none',
        background: theme.palette.common.white,
        left: 0,
        top: 0
    },
    logo: {
        width: 50,
        minWidth: 50,
        minHeight: 50,
        maxWidth: 50,
        maxHeight: 50,
        marginBottom: theme.spacing(4),
    },
    description: {
        marginTop: theme.spacing(1),
        fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
        marginBottom: theme.spacing(4),
        color: '#989ea0',
        fontSize: '1rem',
    },
    hiddenDescription: {
        marginTop: theme.spacing(-1),
        opacity: 0,
        pointerEvents: 'none',
        touchAction: 'none',
        userSelect: 'none'
    }
})

const Preloader = ({ classes }) => (
    <Grid
        className={classes.root}
        alignItems="center"
        justify="center"
        direction="column"
        wrap="nowrap"
        container
    >
        <LogoImage className={classes.logo} />
        <Grow in={true} timeout={{ appear: 0, enter: 150, exit: 0 }}>
            <Typography
                className={classNames(
                    classes.description,
                    classes.hiddenDescription
                )}
                variant="body2"
            >
                Loading Sheetgo
            </Typography>
        </Grow>
    </Grid>
)

Preloader.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(Preloader)
