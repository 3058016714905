// Dialog async of Excel
import { urlAuth } from '../../constants/env'

export const openAuthenticationDialog = async (callback, permission) => {
    const url_auth = permission ? `${urlAuth}?permission=true` : urlAuth
    await window.Office.context.ui.displayDialogAsync(url_auth, { height: 40, width: 30, requireHTTPS: true }, (result) => {
        const loginDialog = result.value
        loginDialog.addEventHandler(window.Office.EventType.DialogMessageReceived, (arg) => {
            const msgFromDialog = JSON.parse(arg.message)
            if (msgFromDialog.success) {
                loginDialog.close()
                if (callback)
                    callback(msgFromDialog.token)
            } else if (msgFromDialog.error || msgFromDialog.msg) {
                loginDialog.close()
                if (callback)
                    callback(msgFromDialog.msg)
                console.log("Unable to authenticate user: " + JSON.stringify(msgFromDialog))
            }
        })
    })
}

export const getFileFromUrl = () => {
    const urlFile = decodeURIComponent(window?.Office?.context?.document?.url || '')
    const fileProps = {
        fileName: "",
        path: "",
        webUrl: urlFile
    }
    const checkUrl = urlFile.split('d.docs.live.net')
    if (checkUrl.length !== 1) {
        let FilePath = checkUrl[1].split('/')
        FilePath.shift()
        FilePath[0] = "Documents"
        FilePath = FilePath.join('/')
        const Path = FilePath.split('/')
        const FileName = Path.pop()
        const PathAddress = Path.join('/')
        fileProps.fileName = FileName.replace(/\^J/gi, ",")
        fileProps.path = PathAddress
    } else {
        const Path = urlFile.slice(urlFile.indexOf("Documents")).split('/')
        const FileName = Path.pop()
        const PathAddress = Path.join('/')
        fileProps.fileName = FileName.replace(/\^J/gi, ",")
        fileProps.path = PathAddress
    }
    return fileProps
}

export const checkLocalUrl = () => {
    // Check if is a local file
    let isLocalFile = null
    try {
        const fileURL = window.Office.context.document.url
        isLocalFile = fileURL.split('/').find(path => path === 'https:')
    } catch (e) {
        console.log(e)
        isLocalFile = true
    }
    
    return (!isLocalFile)
}