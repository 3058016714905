import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import Config from './pages/Config'
// import Auth from './pages/Auth'
// import SheetgoPage from './pages/SheetgoPage'
// import Login from './pages/Login'
// import Transition from './pages/Transition'
// import RemovePage from './pages/Remove'
// import Addin from './pages/Addin'
import AddinOff from './pages/AddinOff'

import { SessionProvider } from './Context'

// import * as ROUTER from './constants/routes'

const App = () => (
	<SessionProvider>
		<Router>
			<Switch>
				<Route
					path={'/'}
					component={AddinOff}
				/>
				{/**
				  	<Route
						path={ROUTER.AUTH}
						component={Auth}
						exact
					/>
					<Route
						path={ROUTER.CONFIG}
						component={Config}
						exact
					/>
					<Route
						path={ROUTER.LOGIN}
						component={Login}
						exact
					/>
					<Route
						path={ROUTER.POPUP_URL}
						component={Transition}
						exact
					/>
					<Route
						path={ROUTER.REMOVE}
						component={RemovePage}
						exact
					/>
					<Route
						path={ROUTER.ADDIN}
						component={Addin}
						exact
					/>
					<Route
						path={ROUTER.ROOT}
						component={SheetgoPage}
						exact
					/>
				**/}
			</Switch>
		</Router>
	</SessionProvider>
)

export default App
