export const SET_FILE= 'SET_FILE'
export const REMOVE_FILE= 'REMOVE_FILE'

export const setFile = (payload) => {
    return {
        type: SET_FILE,
        payload
    }
}

export const removeFile = () => {
    return {
        type: REMOVE_FILE
    }
}