export const SET_USER= 'SET_USER'
export const LOGOUT= 'LOGOUT'
export const MESSAGE= 'MESSAGE'

export const setUser = (payload) => {
    return {
        type: SET_USER,
        payload: payload
    }
}

export const logOut = () => {
    return {
        type: LOGOUT
    }
}

export const setMessage = (message) => {
    return {
        type: MESSAGE,
        message
    }
}