import { makeStyles } from '@material-ui/core'


export default makeStyles(theme => ({
    title: {
        margin: theme.spacing(2, 0, 1),
        fontSize: '1.1rem',
        textAlign: 'center',
        fontFamily: 'ProximaNovaWebBold, Helvetica, Arial, sans-serif'
    },
    bodyText: {
        marginTop: 8,
        marginBottom: 8,
        fontWeight: 400,
        fontSize: '1rem',
        textAlign: 'center',
        fontFamily: 'ProximaNovaWebRegular, Helvetica, Arial, sans-serif',
        color: theme.palette.grey[600]
    },
    buttonAddin: {
        padding: '11px 24px',
        boxShadow: '4px 6px 8px 0px #00000003',
        borderRadius: 24,
        background: '#2979FF',
        color: '#FFF',
        marginTop: theme.spacing(2)
    },
    logo: {
        width: '100%',
        maxHeight: 60,
        maxWidth: 160,
        marginBottom: theme.spacing(2),
        '@media (max-height: 800px)': {
            maxWidth: 130,
            maxHeight: 90,
            marginBottom: theme.spacing(1),
        }
    },
    container: {
        marginTop: theme.spacing(3)
    },
    image: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        maxWidth: '200px',
        width: '50%'
    },
    containerMessage: {
        padding: theme.spacing(2)
    },
    imageContainer: {
        margin: theme.spacing(2, 0)
    }
}))