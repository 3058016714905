import * as msTeams from '@microsoft/teams-js'
import { urlAuth } from '../../constants/env'

export const init = () => {
    msTeams.initialize() 
}

export const openAuthenticationDialog = (callback) => {
    msTeams.authentication.authenticate({
        url: urlAuth,
        width: 600,
        height: 535,
        successCallback: (accessToken) => {
            if (callback)
                callback(accessToken)
        },
        failureCallback: (reason) => {
            if (callback)
                callback(null)
            console.log("Failure: ", reason)
        }
    })
}

export const AppLoaded = () => {
    init()
    msTeams.appInitialization.notifySuccess()
}

export const saveWorkflowId = (id, callback) => {
    msTeams.settings.setValidityState(true)
    msTeams.settings.registerOnSaveHandler((saveEvent) => {
        msTeams.settings.setSettings({
            websiteUrl: `${window.location.origin}/`,
            contentUrl: `${window.location.origin}?workflowID=${id}`,
            removeUrl: `${window.location.origin}/remove`,
            entityId: 'sheetgoTab',
            suggestedDisplayName: 'Sheetgo'
        })
        if (callback)
            callback()
        saveEvent.notifySuccess()
    })
}

export const logout = () => {
    msTeams.settings.setValidityState(true)
    msTeams.settings.registerOnRemoveHandler((removeEvent) => {
        localStorage.removeItem("sheetgo_current_token")
        removeEvent.notifySuccess()
    })
}