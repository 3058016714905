import React, { createContext, useContext, useMemo, useReducer } from 'react'
import combineReducers from 'react-combine-reducers'

import { reducer as SessionReducer, init } from './reducer/session'
import { reducer as fileReducer, initialFileState } from './reducer/file'

const userSession = createContext()

export function SessionProvider(props) {

    const [stateReducer, initialReducer] = combineReducers({
        session: [SessionReducer, init()],
        file: [fileReducer, initialFileState]
    })
    const [state, dispatch] = useReducer(stateReducer, initialReducer)

    const value = useMemo(() => ({
        state,
        dispatch
    }), [state])

    return <userSession.Provider value={value} {...props} />
}

export function useSession() {
    const context = useContext(userSession)
    if (!context) {
        console.log('User out of context provider')
    }
    return context
}



