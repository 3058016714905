import React from 'react'

import {
    Grid,
    Typography
} from '@material-ui/core'

import { OFFLINEFILES } from '../../constants/links'

import LinkStyled from '../components/LinkStyled'

import { ReactComponent as LocalFileFigure } from '../../images/microsoft-offline.svg'

import useStyles from './styles'

const FileOffline = () => {

    const classes = useStyles()

    return (
        <Grid
            className={classes.container}
            alignItems="stretch"
            direction="column"
            justify="center"
            container
        >
            <Grid item>
                <LocalFileFigure className={classes.imageLocalFile} />
            </Grid>
            <Grid item>
                <Typography variant="h3" className={classes.title} >
                    Move your Excel file online to start connecting
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2" className={classes.bodyText}>
                    When you save your files to OneDrive you can create Sheetgo connections between them to share, combine, and distribute data automatically.
                </Typography>
                <Typography variant="body2" className={classes.bodyText}>
                    Check {
                        <LinkStyled
                            href={OFFLINEFILES}
                            target="_blank"
                            variant="primary"
                        >
                            this article
                        </LinkStyled>
                    } to learn how to sync your desktop files to the cloud.
                </Typography>
                <Typography variant="body2" className={classes.bodyText} >
                    Please save your spreadsheet to OneDrive and re-open the file to start using the add-in.
                </Typography>
            </Grid>
        </Grid>
    )
}

export default FileOffline