import Api from '../../../services/Api'
import moment from 'moment'

import {
    SET_USER,
    LOGOUT,
    MESSAGE
} from '../../actions/session'

import { currentEnvName } from '../../../constants/env'

const initialSessionState = {
    email: null,
    id: null,
    locale: null,
    env: null,
    token: null,
    token_expire_at: null,
    isNew: false,
    message: null
}

export const init = () => {
    const current_auth = localStorage.getItem("sheetgo_current_token")
    if (current_auth) {
        const data_token = JSON.parse(new Buffer(current_auth, 'base64').toString())
        if (data_token) {
            const isExpired = moment(data_token.token_expire_at).isBefore()
            if (isExpired) {
                return initialSessionState
            }

            if (!data_token.env || (data_token.env !== currentEnvName)) {
                return initialSessionState
            }

            setTimeout(() => {
                const SheetgoAPI = new Api({ token: data_token.token })
                SheetgoAPI.getRefreshToken()
            }, 0)

            return {
                ...initialSessionState,
                ...data_token
            }
        } else {
            return initialSessionState
        }        
    } else {
        return initialSessionState
    }
}

export function reducer(state = init(), action) {
    switch (action.type) {
        case SET_USER:
            try {
                const toEncode = {
                    ...action.payload,
                    ...{
                        env: currentEnvName
                    }
                }
                const encodedToken = new Buffer(JSON.stringify(toEncode)).toString('base64')
                localStorage.setItem("sheetgo_current_token", encodedToken)
                return {
                    ...state,
                    ...toEncode
                }
            } catch (e) {
                console.error('SET_USER', e)
                return state
            }
        case MESSAGE:
            try {
                return {
                    ...state,
                    ...{
                        message: action.message
                    }
                }
            } catch (e) {
                console.error('MESSAGE', e)
                return state
            }
        case LOGOUT:
            try {
                localStorage.removeItem("sheetgo_current_token")
                return initialSessionState
            } catch (e) {
                console.error('LOGOUT', e)
                return state
            }
        default:
            return state
    }
}