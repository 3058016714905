import { isEmpty } from 'lodash'
import { sheetgoApiUrl } from '../../../constants/env'

class Request {
    constructor({ token }) {
        this.token = token
    }

    getURL(path, method, payload) {
        const url = `${sheetgoApiUrl}/${path}`
        if (method === 'GET' && !isEmpty(payload)) {
            const params = Object.entries(payload)
                .map(([key, val]) => `${key}=${val}`).join('&')
            return url.concat('?', params)
        }
        return url
    }

    getHeaders(auth) {
        const headers = {
            'content-type': 'application/json',
            'Request-Source': 'MS_APP',
            'canary': 'always'
        }

        if (auth) {
            headers['Authorization'] = `Bearer ${this.token}`
        }

        return { headers }
    }

    getSettings(method) {
        return {
            cache: 'no-cache',
            credentials: 'include',
            method
        }
    }

    getBody(method, payload) {
        return ['POST', 'PUT', 'DELETE'].includes(method) && payload
            ? { body: JSON.stringify(payload) }
            : null
    }

    getRequestData(method, auth, payload) {
        return {
            ...this.getSettings(method),
            ...this.getHeaders(auth),
            ...this.getBody(method, payload)
        }
    }

    send(path, method, auth, payload) {
        return new Promise((resolve) => {
            try {
                fetch(
                    this.getURL(path, method, payload),
                    this.getRequestData(method, auth, payload)
                ).then(result => {
                    return result.json()
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    console.log(error)
                    const response = { status: 0 }
                    if (error) {
                        if (error.statusText === 'timeout') {
                            response.status = 12
                            response.message = 'Connection timed out'
                        } else {
                            const text = (error.statusText || 'Unknown error')
                            response.message = text.charAt(0).toUpperCase() + text.slice(1)
                        }
                    }
                    resolve(response)
                })
            } catch (e) {
                const response = { status: 0 }
                resolve(response)
            }
        })
    }
}

export default Request