import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
    root: {
        cursor: 'pointer',
        textDecoration: 'inherit',
        '&:focus': {
            outline: 'none'
        }
    },
    none: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none'
        },
        '&:focus': {
            textDecoration: 'none'
        }
    },
    underline: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        },
        '&:focus': {
            textDecoration: 'underline'
        }
    },
    border: {
        textDecoration: 'none',
        borderBottom: '1px solid transparent',
        '&:hover': {
            textDecoration: 'none',
            borderColor: 'inherit'
        },
        '&:focus': {
            textDecoration: 'none',
            borderColor: 'inherit'
        }
    },
    borderFixed: {
        textDecoration: 'none',
        borderBottom: '1px solid',
        '&:hover': {
            textDecoration: 'none'
        },
        '&:focus': {
            textDecoration: 'none'
        }
    },
    inherit: {
        color: 'inherit',
        '&:hover, &:focus': {
            color: 'inherit'
        }
    },
    normal: {
        color: 'inherit',
        '&:hover, &:focus': {
            color: theme.palette.primary.main
        }
    },
    primary: {
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    bold: {
        color: 'inherit',
        fontWeight: 500,
        '&:hover, &:focus': {
            color: 'inherit'
        }
    }
})

const LinkStyled = ({ children, classes, className, decoration, variant, rel, ...other }) => (
    <a
        className={classNames(
            classes.root,
            {
                [classes.underline]: decoration === 'normal',
                [classes.none]: decoration === 'none',
                [classes.border]: decoration === 'border',
                [classes.borderFixed]: decoration === 'border-fixed',
                [classes.inherit]: variant === 'inherit',
                [classes.normal]: variant === 'normal',
                [classes.primary]: variant === 'primary',
                [classes.bold]: variant === 'bold',
            },
            className
        )}
        rel={rel}
        {...other}
    >
        {children}
    </a>
)

LinkStyled.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    rel: PropTypes.string,
    decoration: PropTypes.oneOf(['normal', 'border', 'border-fixed', 'none']),
    variant: PropTypes.oneOf(['inherit', 'normal', 'primary', 'bold'])
}

LinkStyled.defaultProps = {
    className: null,
    decoration: 'normal',
    variant: 'normal',
    rel: 'noopener noreferrer'
}

export default withStyles(styles)(LinkStyled)
