import { makeStyles } from '@material-ui/core'


export default makeStyles(theme => ({
    title: {
        fontSize: '1.3rem',
        textAlign: 'left',
        paddingLeft: 24,
        paddingRight: 24,
        margin: 16,
        marginLeft: 0,
        marginRight: 0,
        fontWeight: 500
    },
    bodyText: {
        color: '#000',
        fontSize: '1.0rem',
        margin: 16,
        marginLeft: 0,
        marginRight: 0,
        textAlign: 'left',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        paddingLeft: 24,
        paddingRight: 24
    },
    container:{
        textAlign: 'center'
    },
    imageLocalFile: {
        width: 200,
        height: 200,
        margin: 16,
        '@media (max-height: 600px)': {
            width: 150,
            height: 150,
            margin: 2,
        }
    },
    icon: {
        '& > :first-child': {
            fontSize: 28
        }
    }
}))

