import { toArray } from 'lodash'

const envs = {
    prod: {
        name: 'PROD',
        hostname: 'msapp.sheetgo.com',
        apiURL: 'https://api.sheetgo.com',
        appURL: 'https://app.sheetgo.com'
    },
    staging: {
        name: 'STAGING',
        hostname: 'staging-msapp.sheetgo.com',
        apiURL: 'https://staging-api.sheetgo.com',
        appURL: 'https://staging.sheetgo.com'
    },
    alpha: {
        name: 'STAGING',
        hostname: 'alpha-msapp.sheetgo.com',
        apiURL: 'https://alpha-api.sheetgo.com',
        appURL: 'https://alpha.sheetgo.com'
    },
    beta: {
        name: 'STAGING',
        hostname: 'beta-msapp.sheetgo.com',
        apiURL: 'https://beta-api.sheetgo.com',
        appURL: 'https://beta.sheetgo.com'
    },
    dev: {
        name: 'DEV',
        hostname: 'dev-msapp.sheetgo.com',
        apiURL: 'https://dev-api.sheetgo.com',
        appURL: 'https://dev.sheetgo.com'
    },
    local: {
        name: 'LOCAL',
        hostname: 'local-msapp.sheetgo.com',
        apiURL: 'https://local-api.sheetgo.com',
        appURL: 'https://local.sheetgo.com'
    }
}

const currentEnviroment = toArray(envs).find(env => window.location.hostname === env.hostname)

// Configuration of Sheetgo urls
export const sheetgoApiUrl = currentEnviroment.apiURL
export const sheetgoAppUrl = currentEnviroment.appURL
export const urlAuth = window.location.origin + '/sheetgo_auth'

export const currentEnvName = currentEnviroment.name