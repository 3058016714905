import {
    SET_FILE,
    REMOVE_FILE
} from '../../actions/file'

export const initialFileState = {
    'id': null,
    'name': null,
    'mimeType': null,
    'folder': null,
    'provider': null
}

export function reducer(state = initialFileState, action) {
    switch (action.type) {
        case SET_FILE:
            try {
                return {
                    ...state,
                    ...action.payload
                }
            } catch (e) {
                console.error('SET_FILE', e)
                return state
            }
        case REMOVE_FILE:
            try {
                return initialFileState
            } catch (e) {
                console.error('REMOVE_FILE', e)
                return state
            }
        default:
            return state
    }
}