import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ReactComponent as SheetgoLogo } from '../../images/logo/logo_vertical-for-white-background.svg'

import { Button, Grid, Typography } from '@material-ui/core'
import useStyles from './styles'

const AddinOff = () => {
    const classes = useStyles()

    const handleClick = () => {
        window.open(
            'https://app.sheetgo.com/?stm_source=excel-addin&stm_medium=iframe&stm_campaign=temporarily-unavailable',
            '_blank'
        )
    }

    return (
        <>
            <CssBaseline>
                <Grid
                    className={classes.container}
                    container
                >
                    <Grid item md={3} xs={12} />
                    <Grid
                        item
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                        xs={12}
                        md={6}
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            justify="center"
                            alignItems="stretch"
                        >
                            <Grid justify="center" container item>
                                <SheetgoLogo className={classes.logo} />
                            </Grid>
                        </Grid>
                        <Grid
                            className={classes.imageContainer}
                            alignItems="stretch"
                            direction="column"
                            justify="center"
                            container
                            item
                        >
                            <Grid justify="center" container item>
                                <img
                                    className={classes.image}
                                    src="https://storage.googleapis.com/sheetgo-cdn/addin/assets/warning_sign_x2.png"
                                    alt="error figure"
                                />
                            </Grid>
                        </Grid>
                        <Grid item className={classes.containerMessage}>
                            <Typography variant="h3" className={classes.title} gutterBottom>
                                The Sheetgo Excel add-in is temporarily unavailable.
                            </Typography>
                            <Typography variant="body2" className={classes.bodyText}>
                                In the meantime, you can still connect your files using the web app
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.buttonAddin}
                                onClick={handleClick}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                Open the webapp
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12} />
                </Grid>
            </CssBaseline>
        </>
    )
}

export default AddinOff