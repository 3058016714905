export const MSAppGTM = function(msg){
    const dataLayer = window.dataLayer || []

    dataLayer.push({ 
        MSAppGTM: {
            action: msg
        }
    })
}

export const KeyboardPress = () => {
    document.onkeypress =function(e){
        var event = e || window.event
        if(event.ctrlKey && event.key === 'm') {
            // Clean local storage
            localStorage.removeItem("sheetgo_current_token")
            console.log('Storage cleaned!')
            return false
        }
    }
}